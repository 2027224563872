
import {ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import {consultorView} from "@/services/CrudService";

export default {
  name: "visualizarConsultorModal",

  props: {
    consultor: {
      type: Object,
      required: true,
    }
  },

  components: {
    ContentLoader
  },

  setup(props) {
    let animate = ref(true);

    watch(() => props.consultor, () => {
      listaView(props.consultor?.codConsultor);
    })

    const consultorModal: any = ref({});

    async function listaView(codConsultor) {
      animate.value = true;
      let response = await consultorView(codConsultor);
      consultorModal.value = response;
      animate.value = false;
    }

    function tratamentoExibicaoQuadro(cod){ 
      if(cod == 0) return 'Apenas acampa'
      if(cod == 2) return 'Apenas quadro'
      if(cod == 1) return 'Acampa e quadro'
    }

    return {
      tratamentoExibicaoQuadro,
      animate,
      listaView,
      consultorModal
    }
  }
}
